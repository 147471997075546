<template>
  <v-container fluid>
      <v-row>
       <v-col cols="12" md="2">
        <v-select
          v-model="select"
          :items="fechas"
          item-text="name"
          item-value="name"
          label="Seleccionar año"
          persistent-hint
          return-object
          single-line
          v-on:change="getComplaints($event.name)"
        ></v-select>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-chart
          ref="chartAcumulada"
          theme="walden"
          class="ma-auto"
          style="width: 100%; height: 270px"
          :options="heatMapOpt"
          autoresize
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-data-table :headers="headers" :items="items" :sort-by="['created_at']">
          <template v-slot:item.created_at="{ item }">
            {{ item.created_at | moment("utc", "L") }}
          </template>
          <template v-slot:item.action>
            <v-icon color="accent" @click="dialog = !dialog"
              >mdi-open-in-new
            </v-icon>
          </template>
                  <template slot="item.id" scope="props">
    {{ props.index + 1 }}
  </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
// import {TweenMax} from "gsap";
import ECharts from "vue-echarts";
// import ECharts modules manually to reduce bundle size
import * as eutil from "echarts";
import "echarts/lib/chart/line";
import "echarts/lib/chart/bar";
import "echarts/lib/chart/pie";
import "echarts/lib/component/tooltip";
import "echarts/lib/component/toolbox";
import "echarts/lib/component/markLine";
import "echarts/lib/component/legend";
import theme from "@/assets/branding/chartTheme.json";

ECharts.registerTheme("walden", theme);
export default {
  name: "Quejas",
  components: {
    "v-chart": ECharts,
  },
  data: () => ({
      select: { name: "2022" },
    fechas: [{ name: "2021" }, { name: "2022" }],
          año: 2021,
    headers: [
      {
        text: "Creada",
        value: "created_at",
      },
      {
        text: "#",
        value: "id",
      },
      {
        text: "Queja",
        value: "complaint",
      },
      {
        text: "Cliente",
        value: "client.name",
      },
      {
        text: "Producto",
        value: "product.sku",
      },
      {
        text: "Estatus",
        value: "status",
      },
    ],
    items: [],
    heatMapOpt: {},
  }),
  mounted() {
    this.getComplaints(2022);
    this.$refs.chartAcumulada.mergeOptions({
      tooltip: {
        confine: true,
      },
      visualMap: {
        borderColor: "#000000",
        pieces: [
          { max: 1, color: "#d1ecd8" },
          { min: 1, max: 2, color: "#17d453" },
          { min: 2, max: 4, color: "#14b346" },
          { min: 4, max: 6, color: "#f7e00f" },
          { min: 6, max: 8, color: "#f79a0f" },
          { min: 8, color: "#f70f0f" },
        ],
        type: "piecewise",
        orient: "horizontal",
        left: "center",
        top: 65,
      },
      calendar: {
        top: 120,
        left: 30,
        right: 30,
        cellSize: 20,
        range: this.año,
        itemStyle: {
          borderWidth: 1,
          borderColor: "#2b2b2b",
        },
        splitLine: {
          lineStyle: {
            width: 2,
          },
        },
        yearLabel: { show: false },
        dayLabel: {
          nameMap: ["D", "L", "M", "M", "J", "V", "S"],
        },
        monthLabel: {
          nameMap: [
            "Ene",
            "Feb",
            "Mar",
            "Abr",
            "May",
            "Jun",
            "Jul",
            "Ago",
            "Sep",
            "Oct",
            "Nov",
            "Dic",
          ],
        },
      },
      series: {
        type: "heatmap",
        coordinateSystem: "calendar",
        data: [],
      },
    });
  },
  methods: {
    getVirtualData(year) {
      year = year || "2021";
      var date = +eutil.number.parseDate(year + "-01-01");
      var end = +eutil.number.parseDate(+year + 1 + "-01-01");

      var dayTime = 3600 * 24 * 1000;
      var data = [];
      for (var time = date; time < end; time += dayTime) {
        data.push([
          eutil.format.formatTime("yyyy-MM-dd", time),
          Math.floor(Math.random() * 10),
        ]);
      }
      return data;
    },
    getComplaints(year) {
    this.año = year,
      this.$http
        .get("complaints/getComplaints/"+ year)
        .then((res) => {
          this.items = res.data.complaints;
          this.$refs.chartAcumulada.chart.setOption({
            series: {
              type: "heatmap",
              coordinateSystem: "calendar",
              data: res.data.count,
            },
            calendar:{
                range:year
            }
          });
        })
        .catch((error) => {
          if (error.response) {
            if (error.response.data.msg) {
              this.$toast.error(error.response.data.msg);
            } else {
              this.$toast.error("Ocurrio un error inesperado.");
            }
          } else {
            this.$toast.error("Ocurrio un error inesperado.");
          }
        })
        .finally(() => {});
    },
  },
};
</script>

<style scoped></style>
